
import { Component, Vue } from "nuxt-property-decorator";

@Component({})
export default class SwitchLanguageApplication extends Vue {
  showSwitchLocale = false;
  isRightClick: boolean = false;

  get getLanguageName() {
    switch (this.$i18n.locale) {
      case "vi":
        return this.$t("common.vietnamese").toString();
      case "ja":
        return this.$t("common.japanese").toString();
      case "en":
        return this.$t("common.english").toString();
      case "pt":
        return this.$t("common.portuguese").toString();
      default:
        return this.$t("common.english").toString();
    }
    // return this.$i18n.locale === 'vi' ? (this.$t('common.vietnamese').toString() + ' (VI)')
    //   : (this.$i18n.locale === 'ja' ? (this.$t('common.japanese').toString() + ' (JA)') : (this.$t('common.english').toString()) + ' (EN)');
  }
  onChangeLanguage(languageCode) {
    this.$router.push((this as any).switchLocalePath(languageCode));
    // (this.$i18n as any).setLocale(languageCode);
    // (this.$i18n as any).setLocaleCookie(languageCode);
    // window.location.reload();
  }
  closeSwitchLocale() {
    this.showSwitchLocale = false;
  }
  onChangeLocale(e) {
    this.showSwitchLocale = false;
  }
}
