var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: () => {
            ;(_vm.isPopupSearch = false), (_vm.isDropdown = false)
          },
          expression:
            "\n    () => {\n      (isPopupSearch = false), (isDropdown = false);\n    }\n  ",
        },
      ],
      staticClass: "position-relative",
      class: {
        "d-none": _vm.$auth.user && _vm.$auth.user.role !== "CANDIDATE",
      },
    },
    [
      _c("div", { staticClass: "search_wrapper d-inline-flex" }, [
        _c("div", {}, [
          _c("input", {
            ref: "input_search",
            staticClass: "d-inline-flex",
            class: { hide_icon: _vm.searchContent },
            attrs: {
              type: "search",
              placeholder: _vm.$t("search.search"),
              tabindex: "-1",
              maxlength: "500",
            },
            domProps: { value: _vm.searchContent },
            on: {
              input: _vm.onInput,
              focus: function ($event) {
                ;(_vm.isPopupSearch = true), (_vm.isDropdown = false)
              },
              keypress: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.toSearchDetail.apply(null, arguments)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "icon_search d-flex",
            class: { hide_icon: _vm.searchContent },
            attrs: { role: "button", tabindex: "-1" },
            on: { click: _vm.viewPopupSearch },
          },
          [
            _c(
              "svg",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.searchContent,
                    expression: "!searchContent",
                  },
                ],
                attrs: {
                  width: "24",
                  height: "24",
                  viewBox: "0 0 24 24",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M21 19.9648L16.8301 15.7949C18.111 14.2478 18.7469 12.2668 18.6057 10.2632C18.4645 8.25958 17.557 6.38735 16.0716 5.03524C14.5863 3.68313 12.6373 2.95504 10.6293 3.00215C8.62125 3.04926 6.70851 3.86796 5.28823 5.28823C3.86796 6.70851 3.04926 8.62125 3.00215 10.6293C2.95504 12.6373 3.68313 14.5863 5.03524 16.0716C6.38735 17.557 8.25958 18.4645 10.2632 18.6057C12.2668 18.7469 14.2478 18.111 15.7949 16.8301L19.9648 21L21 19.9648ZM6.35154 15.3066C5.46347 14.419 4.85859 13.288 4.6134 12.0566C4.3682 10.8252 4.49372 9.54868 4.97406 8.38861C5.4544 7.22853 6.268 6.23696 7.31194 5.53933C8.35588 4.84169 9.58326 4.46932 10.8389 4.46932C12.0944 4.46932 13.3218 4.84169 14.3658 5.53933C15.4097 6.23696 16.2233 7.22853 16.7036 8.38861C17.184 9.54868 17.3095 10.8252 17.0643 12.0566C16.8191 13.288 16.2142 14.419 15.3262 15.3066C14.7371 15.8998 14.0364 16.3706 13.2646 16.6919C12.4928 17.0131 11.6651 17.1785 10.8291 17.1785C9.99309 17.1785 9.16534 17.0131 8.39353 16.6919C7.62173 16.3706 6.9211 15.8998 6.33201 15.3066H6.35154Z",
                    fill: "#FEFEFF",
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isPopupSearch || _vm.isSearchJobViewMobile,
              expression: "isPopupSearch || isSearchJobViewMobile",
            },
          ],
          staticClass: "group_result",
          class: {
            "landing-responsive": _vm.$device.isMobile && !_vm.$auth.loggedIn,
          },
        },
        [
          _c(
            "div",
            { staticClass: "box_result" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "box_result__select_type position-relative mx-3 mt-3",
                  class: { open: _vm.isDropdown },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between",
                      on: { click: _vm.openDropdown },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "box_result__select_type--name flex-1" },
                        [_c("span", [_vm._v(_vm._s(_vm.selectedType))])]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "icon-style d-inline-flex" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "24",
                              height: "24",
                              viewBox: "0 0 24 24",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                              color: "#525252",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M20 8.28571L18.6667 7L12 13.4285L5.33335 7.00004L4 8.28575L11.9999 16L20 8.28571Z",
                                fill: "#525252",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isDropdown
                    ? _c(
                        "div",
                        {
                          staticClass: "dropdown_type",
                          class: [{ "dropdown_type-ie": _vm.$isIE }],
                        },
                        [
                          _c("div", { staticClass: "mt-1" }),
                          _vm._v(" "),
                          _vm._l(_vm.searchTypes, function (item) {
                            return _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.show,
                                    expression: "item.show",
                                  },
                                ],
                                key: item.id,
                                staticClass: "dropdown_type__item",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.chooseType(item)
                                  },
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(item.name))])]
                            )
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-1" }),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "box_result__search mt-2" },
                _vm._l(_vm.results, function (rs) {
                  return _c(
                    "nuxt-link",
                    {
                      key: rs.id,
                      staticClass:
                        "box_result__search__item d-flex align-items-start py-2 px-3",
                      attrs: { to: _vm.toDetail(rs), "no-prefetch": "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$emit("hide-popup")
                        },
                      },
                    },
                    [
                      _c("img", { attrs: { src: _vm.iconByType, alt: "" } }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "box_result__search__item--name flex-1",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "box-result-custom" },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.$isIE,
                                      expression: "!$isIE",
                                    },
                                  ],
                                },
                                [_vm._v(_vm._s(rs.name))]
                              ),
                              _vm._v(" "),
                              _c(
                                "client-only",
                                [
                                  _c(
                                    "v-clamp",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.$isIE,
                                          expression: "$isIE",
                                        },
                                      ],
                                      staticClass: "box-result-custom",
                                      attrs: { autoresize: "", "max-lines": 2 },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(rs.name) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _vm.$isIE ? _c("div", { staticClass: "mt-2" }) : _vm._e(),
              _vm._v(" "),
              _c(
                "client-only",
                [
                  _c(
                    "infinite-loading",
                    {
                      attrs: { distance: 50 },
                      on: { infinite: _vm.infiniteHandler },
                    },
                    [
                      _c("div", {
                        attrs: { slot: "no-more" },
                        slot: "no-more",
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "d-none opacity-0",
                        attrs: { slot: "no-results" },
                        slot: "no-results",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }