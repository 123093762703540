
import { Component, mixins } from "nuxt-property-decorator";
import Common from "~/mixins/CommonMixins.vue";

@Component({})
export default class FacebookMessenger extends mixins<Common>(Common) {
  mounted() {
    var chatbox = document.getElementById('fb-customer-chat');
    chatbox?.setAttribute("page_id", process.env.FACEBOOK_FANPAGE_ID || '');
    chatbox?.setAttribute("attribution", "biz_inbox");
  }
}
