
import { Component, namespace } from "nuxt-property-decorator";
import CommonMixins from "~/mixins/CommonMixins.vue";
import { Mixins } from 'vue-mixin-decorator';
import BaseNav from "~/argon-components/BaseNav.vue";
import BaseDropdown from "~/argon-components/BaseDropdown.vue";
import CloseButton from "~/argon-components/CloseButton.vue";
import LocaleSwitcher from "~/components/LocaleSwitcher.vue";
import NotificationComponent from "~/components/NotificationComponent.vue";
import SearchNavBarNew from "~/components/SearchNavBarNew.vue";
import SwitchLanguageApplication from "~/components/SwitchLanguageApplication.vue";

const userInfoStore = namespace("userInfo");

@Component({
  components: {
    BaseNav,
    BaseDropdown,
    CloseButton,
    LocaleSwitcher,
    NotificationComponent,
    SearchNavBarNew,
    SwitchLanguageApplication,
    // BaseNav: () => import('~/argon-components/BaseNav.vue'),
    // BaseDropdown: () => import('~/argon-components/BaseDropdown.vue'),
    // CloseButton: () => import('~/argon-components/CloseButton.vue'),
    // LocaleSwitcher: () => import('~/components/LocaleSwitcher.vue'),
    // NotificationComponent: () => import('~/components/NotificationComponent.vue'),
    // SearchNavBarNew: () => import('~/components/SearchNavBarNew.vue'),
    // SwitchLanguageApplication: () => import('~/components/SwitchLanguageApplication.vue'),
    DialogConfirm: () => import('~/components/DialogConfirm.vue'),
    FullModal: () => import('~/components/FullModal.vue')
  },
})
export default class TopBarNav extends Mixins<CommonMixins>(CommonMixins)  {
  @userInfoStore.State userInfo;

  showSearchJob = false;
  isSearchJobViewMobile = false;
  isHide = false;
  isDevelopment = false;
  checkShowLoading = false;
  showConfirmDialog = false;
  linkNavigateToCandidate = '';
  isRightClick: boolean = false;
  showCollapseMobile = false;
  loaded = false;

  created() {
    if (process.env.NODE_ENV === 'development') {
      this.isDevelopment = true;
    }
  }
  mounted(){
    this.checkNavigateUser();
  }

  checkNavigateUser(){
    if (!this.$auth.user) {
      // return (this.$emit('openLoginDialog'));
      this.linkNavigateToCandidate = (this as any).localePath({
        name: "login"
      });
    }
    if (this.$auth.user && this.$auth.user.property_id) {
      this.linkNavigateToCandidate = (this as any).localePath({
          name: "candidates-slug",
          params: { slug: this.$formatSlugUrl(this.$auth.user.name, this.$fromUUID(this.$auth.user.property_id)) }
        });
    } 
    else {
      this.linkNavigateToCandidate = (this as any).localePath({ name: "candidates-create-basic" })
    }
  }

  searchJobViewMobile() {
    this.isSearchJobViewMobile = true;
  }
  isInSession(role: string): boolean {
    if (!this.$auth.user) return false;
    if (this.$auth.user.role != role) return false;
    return true;
  }
  get currentUsername() {
    if(this.$auth.user?.name){
      return this.$store.state.userInfo.userInfo.firstName + ' ' + this.$store.state.userInfo.userInfo.lastName
    }
    return '';
  }
  openLoginDialog(){
    // this.$emit('openLoginDialog');
    this.redirectLoginToUrl();
  }
  gotoRegisterPage() {
    this.$router.push(
      (this as any).localePath({
        name: "sign-up"
      })
    );
  }
  onNavigateToHome() {
    if (this.$auth.user && this.$auth.user.role == 'COMPANY' && this.$auth.user.property_id ) {
      window.scrollTo(0, 0);
      this.$router.push(
        (this as any).localePath({
          name: "companies-slug",
          params: { slug: this.$formatSlugUrl(this.$auth.user.name, this.$fromUUID(this.$auth.user.property_id)) }
        })
      );
    }
    if (this.$auth.user && this.$auth.user.role == 'TRANSLATOR' && this.$auth.user.property_id ) {
      this.$router.push(
        (this as any).localePath({
          name: "translators-slug",
          params: { slug: this.$formatSlugUrl(this.$auth.user.name, this.$fromUUID(this.$auth.user.property_id)) },
          hash: '#new_requests'
        })
      );
    }
    return (this as any).localePath({
      name: "posts"
    });
  }
  get getOnNavigateToHome() {
    return (this as any).localePath({
      name: "posts"
    });
  }
  refreshPage() {
    // if (process.client && this.$auth.loggedIn) {
    window.scrollTo({top: 0, behavior: 'smooth'});
    // }
    if(this.$route.path == this.localePath('forums')) {
      this.$nuxt.$emit("on-refresh-forums");
      this.checkShowLoading = true;
      this.$nuxt.refresh().then(e => {
        setTimeout(() => {
          this.checkShowLoading = false;
        }, 500)
      }).catch(e => {
          this.checkShowLoading = false;
      });
      // this.checkShowLoading = false;
    }
  }
  get forumNav() {
    return (this as any).localePath({
      name: "forums"
    });
  }
  get postNav() {
    return (this as any).localePath({
      name: "posts"
    });
  }
  get newsNav() {
    return (this as any).localePath({
      name: "news"
    });
  }
  onNavigateToCandidate() {
    if (!this.$auth.user) {
      // return (this.$emit('openLoginDialog'));
      this.linkNavigateToCandidate = (this as any).localePath({
        name: "login"
      });
      this.$router.push(this.linkNavigateToCandidate);
    }
    if (this.$auth.user && this.$auth.user.property_id) {
      if (this.$route.path === this.localePath('candidates-slug')) {
        if (process.client && this.$auth.loggedIn) {
          window.scrollTo({top: 0, behavior: 'smooth'});
        }
        this.$nuxt.refresh();
      }
      this.linkNavigateToCandidate = (this as any).localePath({
          name: "candidates-slug",
          params: { slug: this.$formatSlugUrl(this.$auth.user.name, this.$fromUUID(this.$auth.user.property_id)) }
        });

      return this.$router.push(
          this.linkNavigateToCandidate
      );
    } else
    {
      this.linkNavigateToCandidate = (this as any).localePath({ name: "candidates-create-basic" })
      return this.$router.push(
        this.linkNavigateToCandidate
      );
    }

  }
  get onNavigateToCandidate2() {
    if (!this.$auth.user) {
      // return (this.$emit('openLoginDialog'));
      this.linkNavigateToCandidate = (this as any).localePath({
        name: "login"
      });
      return this.linkNavigateToCandidate;
    }
    if (this.$auth.user && this.$auth.user.property_id) {
      if (this.$route.path === this.localePath('candidates-slug')) {
        if (process.client && this.$auth.loggedIn) {
          window.scrollTo({top: 0, behavior: 'smooth'});
        }
        this.$forceUpdate();
      }
      this.linkNavigateToCandidate = (this as any).localePath({
          name: "candidates-slug",
          params: { slug: this.$formatSlugUrl(this.$auth.user.name, this.$fromUUID(this.$auth.user.property_id)) }
        });
      return this.linkNavigateToCandidate;
    } else
    {
      this.linkNavigateToCandidate = (this as any).localePath({ name: "candidates-create-basic" })
      return this.linkNavigateToCandidate;
    }
  }
  onNavigateTo() {
    if(this.isInSession('CANDIDATE')) {
      this.onNavigateToCandidate();
    }
  }
  openLogoutModal(){
    this.$emit('openLogoutModal')
  }
  openChangePasswordModal(){
    this.$emit('openChangePasswordModal')
  }
  openChangeLanguegeModal(){
    this.$emit('openChangeLanguageModal')
  }
  goToListBlock(){
    this.$router.push(
        (this as any).localePath({
          name: "user-block",
        })
      );
  }
  goToListReport(){
    this.$router.push(
      (this as any).localePath({
        name: "reports",
      })
    );
  }
  goToDeleteAccount(){
    this.$router.push(
      (this as any).localePath({
        name: "delete-account",
      })
    );
  }
  goToForums(){
    this.$router.push(this.forumNav);
  }
  goToPost(){
    this.$router.push(this.postNav);
  }
  goToNews(){
    this.$router.push(this.newsNav);
  }
  onChangeSearch(evt){
    this.showSearchJob = !this.showSearchJob;
  }
  get isButtonAuth() {
    return this.$route.path !== this.localePath('/login') && 
      this.$route.path !== this.localePath('/sign-up') && 
      this.$route.path !== this.localePath('/forgot-password') && 
      this.$route.path !== this.localePath('/resend-mail') && 
      this.$route.path !== this.localePath('/invalid-url');
  }
  get showToolbar() {
    return (!this.$auth.loggedIn && this.$i18n.locale !== 'ja') 
      || (this.$auth.user?.role == 'CANDIDATE' && this.$auth.user?.support_language?.code !== 'ja')
  }
  onChangeHideIcon(isHide) {
    this.isHide = isHide;
  }
  gotoCreatePost() {
    if(!this.$auth.user.property_id) {
      this.showConfirmDialog = true;
      return;
    }
    this.$router.push(this.localePath({
      name: 'posts-create-post'
    }))
  }
  goToAboutUs() {
    this.$router.push(this.localePath({
      name: 'about'
    }))
  }
  goToPrivacy() {
    this.$router.push(this.localePath({
      name: 'privacy'
    }))
  }
  goToContactUs() {
    this.$router.push(this.localePath({
      name: 'contact'
    }))
  }
  get checkShowCreatePost() {
    return this.$auth.loggedIn && this.$auth.user.role === 'CANDIDATE' && this.$route.name?.includes('posts');
  }
  fnActionConfirm() {
    this.showConfirmDialog = false;
    this.$router.push((this as any).localePath({
      name: "candidates-create-basic",
      query: { redirect: 'back' }
    }));
  }
  checkRefresh(routeName) {
    if (process.client && this.$route.path === routeName) {
       if (this.loaded) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        this.$nuxt.refresh();
      }
    } else {
      this.loaded = false
      setTimeout(() => {
        this.loaded = true;
      }, 1000);
    }
  }
  checkClickEvent(e) {
    if (this.getWidthScreen < 768) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  }
}
