var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClose,
          expression: "onClose",
        },
      ],
      staticClass: "notification-icon-badge position-relative",
      on: { click: _vm.lazyLoading },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "tippy",
              rawName: "v-tippy",
              value: {
                delay: [1000, 200],
                placement: "top",
                arrow: true,
                touch: false,
                zIndex: _vm.isRightClick ? -1 : 9999,
              },
              expression:
                "{ delay: [1000, 200], placement : 'top',  arrow: true, touch: false, zIndex: isRightClick ? -1 : 9999}",
            },
          ],
          staticClass: "notification-icon cursor-pointer",
          attrs: { content: `${_vm.$t("common.notification")}` },
          on: {
            contextmenu: function ($event) {
              _vm.isRightClick = true
            },
            mouseover: function ($event) {
              _vm.isRightClick = false
            },
          },
        },
        [
          !_vm.isDetectMobile
            ? _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "24",
                    height: "25",
                    viewBox: "0 0 24 25",
                    fill: "none",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M13.73 21.5C13.5542 21.8031 13.3018 22.0547 12.9982 22.2295C12.6946 22.4044 12.3504 22.4965 12 22.4965C11.6496 22.4965 11.3054 22.4044 11.0018 22.2295C10.6982 22.0547 10.4458 21.8031 10.27 21.5M18 8.5C18 6.9087 17.3679 5.38258 16.2426 4.25736C15.1174 3.13214 13.5913 2.5 12 2.5C10.4087 2.5 8.88258 3.13214 7.75736 4.25736C6.63214 5.38258 6 6.9087 6 8.5C6 15.5 3 17.5 3 17.5H21C21 17.5 18 15.5 18 8.5Z",
                      stroke: "#8D92AA",
                      "stroke-width": "2",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  }),
                ]
              )
            : _c(
                "svg",
                {
                  attrs: {
                    width: "20",
                    height: "20",
                    viewBox: "0 0 20 20",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M7.91669 17.0835H12.0834C12.0834 17.636 11.8639 18.1659 11.4732 18.5566C11.0825 18.9473 10.5526 19.1668 10 19.1668C9.44749 19.1668 8.91758 18.9473 8.52688 18.5566C8.13618 18.1659 7.91669 17.636 7.91669 17.0835Z",
                      fill: "#8D92AA",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M17.5 14.7502C17.2407 14.5682 17.0294 14.326 16.8843 14.0444C16.7391 13.7629 16.6644 13.4503 16.6667 13.1335V9.16683C16.6656 7.68971 16.1739 6.25478 15.2689 5.08735C14.364 3.91992 13.0969 3.08611 11.6667 2.71683C11.6749 2.64484 11.6749 2.57215 11.6667 2.50016C11.6667 2.05814 11.4911 1.63421 11.1785 1.32165C10.8659 1.00909 10.442 0.833496 9.99999 0.833496C9.55797 0.833496 9.13404 1.00909 8.82148 1.32165C8.50892 1.63421 8.33333 2.05814 8.33333 2.50016C8.32514 2.57215 8.32514 2.64484 8.33333 2.71683C6.90311 3.08611 5.63604 3.91992 4.73105 5.08735C3.82607 6.25478 3.33443 7.68971 3.33333 9.16683V13.1002C3.33664 13.4305 3.25669 13.7564 3.10085 14.0476C2.94501 14.3389 2.71831 14.5863 2.44166 14.7668C2.32585 14.8431 2.2309 14.9471 2.16542 15.0693C2.09994 15.1915 2.06599 15.3282 2.06666 15.4668V15.8335C2.06666 16.0545 2.15446 16.2665 2.31074 16.4228C2.46702 16.579 2.67898 16.6668 2.89999 16.6668H17.0667C17.2877 16.6668 17.4996 16.579 17.6559 16.4228C17.8122 16.2665 17.9 16.0545 17.9 15.8335V15.4418C17.8966 15.3023 17.8582 15.1658 17.7883 15.0449C17.7184 14.9241 17.6193 14.8227 17.5 14.7502Z",
                      fill: "#8D92AA",
                    },
                  }),
                ]
              ),
        ]
      ),
      _vm._v(" "),
      _vm.unreadCount > 0
        ? _c(
            "div",
            {
              staticClass:
                "position-absolute notification-unread-count font-08 flex-center",
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.unreadCount > 99 ? `99+` : _vm.unreadCount)),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "position-absolute full-notification-area sync-width-popup",
          class: _vm.showNotification ? null : "d-none",
        },
        [
          _c("top-notifications", {
            ref: "notifications",
            attrs: {
              notifications: _vm.notifications,
              fetchNotifications: _vm.fetchNotifications,
              isloading: _vm.isloading,
            },
            on: {
              "error-notification": (errorCode) => {
                _vm.$emit("error-notification", errorCode)
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }