
import { Component, mixins, Watch } from "nuxt-property-decorator";
import { namespace } from "vuex-class";
import { Notification } from "~/modals";
import { plainToClass } from "class-transformer";
import SetWidthDOM from "~/mixins/SetWidthDOM.vue";

const NotificationStore = namespace("notification");
const ProfileStore = namespace("profile");
// const CompanyStore = namespace("company");
@Component({
  components: {
    TopNotifications: () => import("~/components/TopNotifications.vue"),
  },
  data: () => ({
    notifications: [],
    showNotification: false
  })
})
export default class NotificationComponent extends mixins<SetWidthDOM>(SetWidthDOM) {
  @NotificationStore.State newNotification;
  @NotificationStore.State unreadNotifications;
  @NotificationStore.Action resetUnreadNotifications;
  @NotificationStore.Action decreaseUnreadNotifications;
  @NotificationStore.Action increaseUnreadNotifications;
  @NotificationStore.Action fetchUnreadNotifications;
  @NotificationStore.Action setUnreadNotifications;
  @ProfileStore.State profiles;
  // @CompanyStore.Action setCurrentCompany;
  // @CompanyStore.State company;

  notifications: Notification[] = [];
  showNotification?: boolean;
  isFetching?: boolean = false;
  nextPage?: number;
  scrollHeight?: number;
  isRightClick: boolean = false;
  $refs!: {
    notifications: HTMLFormElement;
  };
  notificationTypesNotNotify: string[] = [
    "",
    "READ_MESSAGE",
    "SELF_READ_MESSAGE",
    "READ_NOTIFICATION",
    "COMMENT_JOB",
    "BLOG",
    "MESSAGE_JOB_APPLICATION",
    "TRANSLATION_MESSAGE_JOB_APPLICATION",
    "MESSAGE_REQUEST",
    "DELETE_FILE_MESSAGE_JOB_APPLICATION",
    "DELETE_CONTENT_MESSAGE_JOB_APPLICATION",
    "EDIT_MESSAGE_JOB_APPLICATION",
    "DELETE_FILE_MESSAGE_REQUEST",
    "DELETE_CONTENT_MESSAGE_REQUEST",
    "EDIT_MESSAGE_REQUEST",
    "TRANSLATOR_CREATE_TRANSLATION",
    "READ_MESSAGE_JOB_APPLICATION",
    "READ_MESSAGE_REQUEST",
    "VIDEO_CALL",
    "NEWS"
  ];
  notificationTypes = {
    STATUS_JOB_APPLICATION: "STATUS_JOB_APPLICATION",
    STATUS_REQUEST: "STATUS_REQUEST",
    READ_NOTIFICATION: "READ_NOTIFICATION"
  };
  notifyContentSameId = [
    "ANSWER_ANSWER_QUESTION",
    "ANSWER_QUESTION",
    "LIKE_QA",
    "ALSO_ANSWER_ANSWER_QUESTION",
    "LIKE_ANSWER_QA",
    "ALSO_ANSWER_QUESTION"
  ]
  page: number = 1;
  total: number = 1;
  isloading = true;
  notShowNotificationRequestContent= [];
  notShowNotificationApplicationContent = [];

  created() {
    this.fetchUnreadNotifications();
  }
  
  onClose() {
    this.showNotification = false;
  }
  
  async lazyLoading(e){
    this.showNotification = !this.showNotification;
    if (this.showNotification && (!this.notifications || this.notifications.length == 0)) {
      await this.fetchNotifications(1);
    }
  }

  @Watch("newNotification")
  async onReceiveNotification(newVal: Notification, oldVal: Notification) {
    if (!newVal) {
      return;
    }
    this.isloading = false;
    if(newVal.notificationType === this.notificationTypes.READ_NOTIFICATION) {
      if (this.unreadNotifications > 0) {
        this.decreaseUnreadNotifications();
      }
      let readedNotification = this.notifications.find((noti) => noti.id === newVal.id);
      if(readedNotification) {
        readedNotification.read = true;
      }
      return;
    }
    // if(newVal.notificationType === 'ADMIN' && !this.$route.name?.includes('companies-slug')) {
    //   if (['ADMIN_DENIED_REQUEST_ACTIVE_ACCOUNT', 'ADMIN_DEACTIVATE_ACCOUNT', 
    //         'USER_INVOICES_NEED_PAYMENT'].includes((newVal.content as string))) {
    //     if ((this.company as any).active) {
    //       this.setCurrentCompany({...this.company, active: false});
    //     }
    //   } else {
    //     this.setCurrentCompany({...this.company, active: true});
    //   }
    // }
    if (JSON.stringify(newVal) != JSON.stringify(oldVal) && (newVal.id != (oldVal?.id ?? -6) || 
      // (newVal.content == "ANSWER_ANSWER_QUESTION") || (newVal.content == "ANSWER_QUESTION") || newVal.content == "ALSO_ANSWER_ANSWER_QUESTION")) {
      this.notifyContentSameId.includes(newVal.content || ''))) {
      if (this.notificationTypesNotNotify.includes((newVal.notificationType || ''))) {
        return;
      }
      // this.increaseUnreadNotifications();
      if(this.notifications.length > 0) {
        const idx = this.notifications.findIndex(_o => _o.id === newVal.id);
        if (idx < 0) {
          this.notifications.unshift({ ...newVal, read: false });
        } else {
          this.notifications.splice(idx,1);
          this.notifications.unshift({ ...newVal, read: false });
        }
        // const countUnread = this.notifications.reduce((initVal, currVal) => initVal += (!currVal.read ? 1 : 0), 0);
        // if (this.unreadCount !== countUnread) {
        //   this.setUnreadNotifications(countUnread);
        // }
      }
      this.fetchUnreadNotifications();
    }
  }

  get unreadCount() {
    return this.unreadNotifications;
  }

  async fetchNotifications(page) {
    try {
      if (this.isFetching) return;
      this.isFetching = true;
      let result = await this.$axios.get(
        `/api/v1/community/notifications?page=${page}`
      );
      const results = result['data'];
      this.isloading = false;
      if (page == 1) {
        this.notifications = [];
        let _tmpNotifications = [];
        _tmpNotifications = results["data"];
        this.notifications = plainToClass(Notification, _tmpNotifications);
        this.$refs["notifications"].scrollToTop();
      } else {
        let bottomNotiID = this.notifications![0]!.id;
        results["data"].forEach(result => {
          const notification: Notification = plainToClass(Notification, result);
          this.notifications!.push(notification);
        });
      }
      this.isFetching = false;
      this.page = page;
      let totalPage = results!["meta"]!["total_pages"]
        ? Number(results["meta"]["total_pages"])
		: this.total;
			this.total = totalPage;
			if(page < totalPage) return page + 1;
			else return 0;
    } catch (err) {
      this.isFetching = false;
    }
  }
}
