export default {
	en: {
		hint: 'Choose a file or drag it here to upload',
		loading: 'Uploading…',
		noSupported: 'Browser is not supported, please use IE10+ or other browsers',
		success: 'Upload success',
		fail: 'Upload failed',
		preview: 'Preview',
		btn: {
			off: 'Cancel',
			close: 'Close',
			back: 'Back',
			save: 'Save'
		},
		error: {
			onlyImg: 'Image only',
			outOfSize: 'Image exceeds size limit: ',
			lowestPx: 'Insufficient image resolution. It should be at least: '
		}
	},
	ja: {
		hint: 'ファイルを選択するかここにドラッグしてアップロードしてください。',
		loading: 'アップロード中...',
		noSupported: 'このブラウザは対応されていません。IE10+かその他の主要ブラウザをお使いください。',
		success: 'アップロード成功',
		fail: 'アップロード失敗',
		preview: 'プレビュー',
		btn: {
			off: 'キャンセル',
			close: '閉じる',
			back: '戻る',
			save: '保存'
		},
		error: {
			onlyImg: '画像のみ選択可能です',
			outOfSize: '画像サイズが上限を超えています。上限: ',
			lowestPx: '画像の解像度が不十分です。少なくとも（解像度）以上の画像を使用してください。: '
		}
	},
	vi: {
        hint: 'Nhấp hoặc kéo tệp vào đây để tải lên',
        loading: 'Đang tải lên…',
        noSupported: 'Trình duyệt không được hỗ trợ, vui lòng sử dụng IE10 + hoặc các trình duyệt khác',
        success: 'Tải lên thành công',
        fail: 'Tải lên không thành công',
        preview: 'Xem trước',
        btn: {
            off: 'Hủy bỏ',
            close: 'Đóng',
            back: 'Trở lại',
            save: 'Lưu'
        },
        error: {
            onlyImg: 'Chỉ hình ảnh',
            outOfSize: 'Hình ảnh vượt quá giới hạn kích thước: ',
            lowestPx: 'Độ phân giải hình ảnh không đủ. Ít nhất là: '
        }
    }
};
