
import { Component, Prop, Watch } from "nuxt-property-decorator";
import debounce from "debounce";
import qs from "qs";
import { Mixins } from "vue-mixin-decorator";
import Common from "~/mixins/CommonMixins.vue";

@Component({})
export default class SearchNavBarNew extends Mixins<Common>(Common) {
  @Prop({ type: Boolean, required: false, default: false })
  isSearchJobViewMobile;
  icons = {
    community: require("~/assets/icons/icon-community-search.svg"),
    news: require("~/assets/icons/icon-news-search.svg"),
    blog: require("~/assets/icons/icon-blog-search.svg"),
  };
  currentType = "community";
  isDropdown = false;
  isPopupSearch = false;
  searchContent = "";
  valueSelect = -1;
  results: any = [];
  isLoading = false;
  nextPage: number = 2;
  totalPage: number = 0;

  viewPopupSearch(e) {
    this.isPopupSearch = !this.isPopupSearch;
    const input = this.$el.querySelector('input[type="text"]');
    input?.setAttribute("tabindex", "0");
    (input as any)?.focus();
  }
  @Watch("isPopupSearch")
  showPopupSearch(newVal, oldVal) {
    if (this.getWidthScreen >= 768) {
      return;
    }
    if (newVal !== oldVal) {
      this.$emit("hide-icon", newVal);
    }
  }
  chooseType(type) {
    this.currentType = type.id;
    this.valueSelect = type.type;
    // this.searchContent = '';
    this.results = [];
    this.isDropdown = false;
    const input = this.$el.querySelector('input[type="search"]');
    input?.setAttribute("tabindex", "0");
    (input as any)?.focus();
    this.debounceInput();
  }
  get searchTypes() {
    return [
      {
        id: "blog",
        name: this.$t("common.hanbook"),
        path: [
          (this as any).localePath("/posts"),
          (this as any).localePath("/posts/search"),
        ],
        type: 2,
        endpoint: "/api/v1/blogs/autocomplete",
        show:
          (!this.$auth.loggedIn && this.$i18n.locale !== "ja") ||
          (this.$auth.user?.role == "CANDIDATE" &&
            this.$auth.user?.support_language?.code !== "ja"),
      },
      {
        id: "news",
        name: this.$t("common.news"),
        path: [
          (this as any).localePath("/news"),
          (this as any).localePath("/news/search"),
        ],
        type: 1,
        endpoint: "/api/v1/news/autocomplete",
        show: true,
      },
      {
        id: "community",
        name: this.$t("entry_sheet.question"),
        path: [
          (this as any).localePath("/forums"),
          (this as any).localePath("/forums/search"),
          (this as any).localePath("/forums/my-question"),
          (this as any).localePath("/forums/tags"),
        ],
        type: 0,
        endpoint: "/api/v1/communities/autocomplete",
        show: true,
      },
    ];
  }
  get iconByType() {
    return this.icons[this.currentType];
  }
  @Watch("isPopupSearch")
  onShowPopup(newVal: boolean, oldVal: boolean) {
    if (!newVal || !this.searchContent) {
      return;
    }
    this.debounceInput();
  }
  @Watch("$route.path", { immediate: true })
  onRoute(newVal: string, oldVal: string) {
    if (!newVal || newVal === oldVal) {
      return;
    }
    this.isPopupSearch = false;
    this.results = [];
    const _type = this.searchTypes.find((o) =>
      o.path.some((_path) => newVal.includes(_path))
    );
    this.currentType = _type?.id ?? this.searchTypes[0].id;
    this.valueSelect = _type?.type ?? this.searchTypes[0].type;
  }
  @Watch("$route.query", { immediate: true })
  onRouteQuery(newVal, oldVal) {
    this.searchContent = newVal.search_content || "";
  }
  get selectedType() {
    const type = this.searchTypes.find((o) => o.id === this.currentType);
    return type?.name ?? "";
  }
  openDropdown() {
    this.isDropdown = !this.isDropdown;
  }
  onInput(e) {
    this.searchContent = e.target.value;
    this.debounceInput();
  }
  debounceInput = debounce(this.autocompleteSearch, 300).bind(this);
  get endpointSearch() {
    return (
      this.searchTypes?.find((_o) => _o.id === this.currentType) ?? {
        endpoint: "",
        type: 0,
      }
    );
  }
  async autocompleteSearch(e) {
    //check predictive text mobile
    try {
      // if (this.$device.isMobile) {
      // if(e && e.target && e.target.value && !this.searchContent) {
      // }
      // }
    } catch (error: any) {}
    if (!this.searchContent.trim()) {
      this.results = [];
      return;
    }
    let params = {
      search_content: this.searchContent.trim(),
      language_code: this.$i18n.locale,
      // 'type': this.endpointSearch.type
      paging: 15,
    };
    this.nextPage = 1;
    try {
      this.isLoading = true;
      const rs = await this.$axios.$get(
        this.endpointSearch.endpoint + "?" + qs.stringify(params)
      );
      this.results = rs["data"];
      this.totalPage = rs["meta"].total_pages;
      this.nextPage = 2;
      this.isLoading = false;
    } catch (error) {                                                         
      this.isLoading = false;
    }
  }
  toDetail(result) {
    let name = "";
    if (this.currentType === "community") {
      name = "forums-slug";
    }
    if (this.currentType === "blog") {
      name = "posts-slug";
    }
    if (this.currentType === "news") {
      name = "news-slug";
    }
    return (this as any).localePath({
      name: name,
      params: {
        slug: this.$formatSlugUrl(
          result.name || "",
          this.$fromUUID(result.id || "")
        ),
      },
      query: {
        language:
          this.$auth.loggedIn && name == "news-slug"
            ? this.$auth.user.support_language.code
            : "",
      },
    });
  }
  toSearchDetail() {
    this.isPopupSearch = false;
    this.$emit("hide-popup");
    switch (this.valueSelect) {
      case 0:
        if (this.$route.path.includes("/forums/search")) {
          if (process.client) {
            window.scrollTo({
              top: 0,
            });
          }
          this.$router.push(
            (this as any).localePath({
              name: "forums-search",
              query: {
                name: this.searchContent.trim(),
                search_content: this.searchContent.trim(),
                hashtag_ids: this.$route.query.hashtag_ids || "",
              },
            })
          );
          break;
        }
        this.$router.push(
          (this as any).localePath({
            name: "forums-search",
            query: {
              name: this.searchContent.trim(),
              search_content: this.searchContent.trim(),
            },
          })
        );
        break;
      case 1:
        if (this.$route.path.includes("/news/search")) {
          if (process.client) {
            window.scrollTo({
              top: 0,
            });
          }
        }
        this.$router.push(
          (this as any).localePath({
            name: "news-search",
            query: {
              name: this.searchContent.trim(),
              search_content: this.searchContent.trim(),
            },
          })
        );
        break;
      case 2:
        if (this.$route.path.includes("/posts/search")) {
          if (process.client) {
            window.scrollTo({
              top: 0,
            });
          }
        }
        this.$router.push(
          (this as any).localePath({
            name: "posts-search",
            query: {
              name: this.searchContent.trim(),
              search_content: this.searchContent.trim(),
            },
          })
        );
        break;
      default:
        break;
    }
    (this as any).$refs["input_search"]?.blur();
  }
  async infiniteHandler($state) {
    if (this.totalPage < 2) {
      $state.complete();
      return;
    }
    this.$watch(
      () => this.searchContent.trim(),
      (newVal, oldVal) => {
        // if (!newVal) {
        //   $state.reset();
        //   this.nextPage = 1;
        //   return;
        // }
        $state.reset();
        this.nextPage = 1;
        return;
      }
    );
    this.$watch(
      () => this.currentType,
      (newVal, oldVal) => {
        if (!newVal) {
          return;
        }
        this.nextPage = 1;
        $state.reset();
        return;
      }
    );
    let params = {
      search_content: this.searchContent.trim(),
      language_code: this.$i18n.locale,
      paging: 15,
      page: this.nextPage,
    };
    try {
      this.isLoading = true;
      const rs = await this.$axios.$get(
        this.endpointSearch.endpoint + "?" + qs.stringify(params)
      );
      if (rs["data"].length) {
        rs["data"].forEach((result) => {
          this.results!.push(result);
        });
        $state.loaded();
      } else {
        $state.complete();
      }
      this.isLoading = false;
      this.nextPage++;
    } catch (error) {
      this.isLoading = false;
    }
  }
}
